import { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { MdDashboard } from "react-icons/md";
import { TiClipboard } from "react-icons/ti";
import { AiOutlineMonitor } from "react-icons/ai";
import { CgUserList } from "react-icons/cg";
import { BiUserCircle, BiWallet } from "react-icons/bi";
import { RiLogoutBoxLine } from "react-icons/ri";

import { authActions } from "../../redux/actions";

import logo from "../../assets/images/logooo.png";

import styles from "./SideNav.module.scss";

const paths = [
  {
    icon: MdDashboard,
    text: "Dashboard",
    path: "",
    exact: true,
  },
  {
    icon: TiClipboard,
    text: "Your waybills",
    path: "/your-orders",
  },
  {
    icon: TiClipboard,
    text: "Customer Net",
    path: "/customer-net",
  },
  {
    icon: TiClipboard,
    text: "Book Waybill",
    path: "/book-waybill",
  },

  {
    icon: TiClipboard,
    text: "Your collections",
    path: "/your-collections",
  },
  {
    icon: AiOutlineMonitor,
    text: "Track waybill",
    path: "/track-order",
  },
  {
    icon: CgUserList,
    text: "Your Transactions",
    path: "/your-transactions",
  },
  {
    icon: BiUserCircle,
    text: "Your profile",
    path: "/your-profile",
  },
  {
    icon: BiWallet,
    text: "Top-up Wallet",
    path: "/top-wallet",
  },
];

const isActive = (pagePath, linkPath) => {
  return pagePath.substring(10) === linkPath;
};

const SideNav = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);

  const toggleSideBar = () => setOpen((o) => !o);
  let match = "/dashboard";
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    dispatch(authActions.logout());
    navigate("/login");
  };

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    const resize = window.addEventListener("resize", () => {
      if (isMounted) {
        setOpen(false);
      }
    });

    return () => window.removeEventListener("resize", resize);
  }, [isMounted]);

  return (
    <nav className={styles.nav}>
      <button
        onClick={toggleSideBar}
        className={classNames(styles.toggler, {
          [styles.on]: open,
        })}
      >
        <span></span>
      </button>
      <ul
        className={classNames(styles.menu, {
          [styles.open]: open,
        })}
      >
        <li>
          <NavLink to="/" className={styles.logo}>
            <div>
              <img src={logo} alt="logo" />
            </div>
          </NavLink>
        </li>
        {paths.map((p) => (
          <li onClick={toggleSideBar} key={p.path} className={""}>
            <NavLink
              to={`${match}${p.path}`}
              className={({ isActive }) =>
                classNames({
                  [styles.active]: isActive,
                })
              }
              end={p.exact}
            >
              <p.icon
                size={24}
                color={isActive(location.pathname, p.path) ? "#fff" : null}
              />
              <span className="">{p.text}</span>
            </NavLink>
          </li>
        ))}

        <li>
          <button onClick={logout} className={styles.logout}>
            <RiLogoutBoxLine size={24} />
            <span className="">Logout</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export { SideNav };
