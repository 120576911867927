import React, { useEffect, useState } from "react";
import { Container, Header, Spinner2 } from "../../../components";
import classes from "./BookWaybill.module.scss";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { bindActionCreators } from "redux";
import { quoteActions, cityStateActions } from "../../../redux/actions";
import { makeRequest } from "../../../utils/makeRequest";
import {
  ButtonLink,
  Inputs,
  SelectSearchDropDown,
} from "../../../../components/UI";
import {
  item,
  bookWaybillSchema,
  bookInitValues,
} from "../../../../pages/Normal/GetQuote/schema";
import { formatCurrency } from "../../../utils/helpers";
import { useNavigate } from "react-router-dom";

const transformOptions = (options) => {
  return options.map((o) => ({
    ...o,
    value: o.id,
  }));
};

const transformHubs = (options) => {
  return options
    .filter((o) => o.isActive)
    .map((o) => ({
      ...o,
      label: o.name,
      value: `${o.description}-----${o.id}`,
    }));
};

const calculateTotal = (items) => {
  const totalAmount = items.reduce((sum, it) => {
    sum = sum + +it.amount;
    return sum;
  }, 0);
  return totalAmount;
};

const isValidItem = (row) => {
  if (!row) {
    return false;
  }

  const item = { ...row };
  delete item?.amount;
  delete item?.error;

  console.log({ roww: item });

  let valid = true;

  for (const prop in item) {
    valid = valid && item[prop];
  }

  return valid;
};

const BookWaybill = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [departureState, setDepartureState] = useState("");
  const [arrivalState, setArrivalState] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(bookWaybillSchema),
    defaultValues: bookInitValues,
  });
  const { fields, append, remove } = useFieldArray({
    name: "items",
    control,
  });

  const watchFieldArray = watch("items");
  const watchFields = watch([
    "deliveryTypeId",
    "departureHubId",
    "destinationHubId",
    "serviceTypeId",
    "departureHubAdd",
    "destinationHubAdd",
    "pickupLocation",
  ]);
  const dId = watchFields[0];
  const clearFields = () => {
    if (watchFields[1]) {
      setValue("departureHubId", "");
    }

    if (watchFields[2]) {
      setValue("destinationHubAdd", "");
      setValue("destinationHubId", "");
    }

    if (watchFields[6]) {
      setValue("pickupLocation", "");
    }
  };

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const {
    arrivalHubs,
    departureHubs,
    arrivalStatus,
    departureStatus,
    arrivalErr,
    departureErr,
    serviceTypes,
    serviceTypesErr,
    serviceTypesStatus,
    deliveryTypes,
    deliveryTypesErr,
    deliveryTypesStatus,

    itemTypesStatus,
    shipmentTypes,
    shipmentTypesErr,
    shipmentTypesStatus,
  } = useSelector((state) => state.quoteHubs);

  const { user } = useSelector((state) => state);

  const { states, statesStatus, statesErr } = useSelector(
    (state) => state.cityState
  );

  const {
    getHubs,
    getServiceTypes,
    getDeliveryTypes,
    getItemTypes,
    getShipmentTypes,
  } = bindActionCreators(quoteActions, dispatch);

  const { getStates } = bindActionCreators(cityStateActions, dispatch);

  const getQuote = async (item, index) => {
    const form = getValues();

    const body = {
      taxId: "VAT",
      insuranceId: "Insurance_2",
      packagingCostId: "ABC_test2",
      totalKilometers: undefined,
      deliveryTypeId: form.deliveryTypeId,
      customerTypeId: user?.customerTypeId || "Regular",
      departureHubId: form.departureHubId,
      destinationHubId: form.destinationHubId,
      serviceTypeId: form.serviceTypeId,
      amount: undefined,
      error: undefined,
      itemTypeId: item?.itemTypeId ? item.itemTypeId : "",
      declared: +item?.declared ? +item.declared : 0,
      quantity: +item?.quantity ? +item.quantity : 0,
      weight: +item?.weight ? +item.weight : 0,
      shipmentTypeId: item?.shipmentTypeId ? item.shipmentTypeId : "",
    };
    setValue(`items.${index}.error`, "");
    setValue(`items.${index}.amount`, "");

    const data = await makeRequest({
      path: `/Orders/GetQuote`,
      method: "POST",
      body,
    });

    if (data.error) {
      setValue(`items.${index}.error`, data.error);
    } else {
      const res = JSON.parse(data.data);
      setValue(`items.${index}.amount`, +res.amount);
    }
  };

  const check = async () => {
    const items = controlledFields;

    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      const {
        deliveryTypeId,
        departureHubId,
        destinationHubId,
        pickupLocation,
        serviceTypeId,
      } = getValues();
      const valid =
        isValidItem(item) &&
        destinationHubId &&
        serviceTypeId &&
        deliveryTypeId &&
        departureHubId &&
        (dId === "HD" ? pickupLocation : true);
      console.log({ isValide: valid });

      if (valid) {
        getQuote(item, index);
      }
    }
  };

  const getItemTypeId = (itemType) => {
    return itemType === "CAR" ? "Normal Car" : "Ordinary Par";
  };

  const onChange = (e, index) => {
    const { name, value } = e.target;
    const actualName = name.split(".")[2];

    let item = {
      ...controlledFields[index],
      [actualName]: value,
    };

    if (actualName.toLowerCase() === "itemdescription") {
      return;
    }

    if (actualName.toLowerCase() === "shipmenttypeid") {
      const val = getItemTypeId(value);
      item.itemTypeId = val;
      setValue(`items.${index}.itemTypeId`, val);
    }
    if (actualName.toLowerCase() === "weight" && Number(value) >= 10) {
      item.shipmentTypeId = "CAR";
      item.itemTypeId = "Normal Car";

      setValue(`items.${index}.shipmentTypeId`, "CAR");
      setValue(`items.${index}.itemTypeId`, "Normal Car");
    }
    if (
      actualName.toLowerCase() === "shipmenttypeid" &&
      Number(item.weight) >= 10
    ) {
      item.itemTypeId = "Normal Car";

      setValue(`items.${index}.shipmentTypeId`, "CAR");
      return;
    }

    const {
      deliveryTypeId,
      departureHubId,
      destinationHubId,
      pickupLocation,
      serviceTypeId,
    } = getValues();
    const valid =
      isValidItem(item) &&
      destinationHubId &&
      serviceTypeId &&
      deliveryTypeId &&
      departureHubId &&
      (dId === "HD" ? pickupLocation : true);

    if (valid) {
      getQuote(item, index);
    }
  };

  const onChangeField = async (e) => {
    const { name, value } = e.target;
    const item = controlledFields[0];

    const form = getValues();

    for (let i = 0; i < controlledFields.length; i++) {
      setValue(`items.${i}.error`, "");
      setValue(`items.${i}.amount`, "");
    }

    let {
      deliveryTypeId,
      departureHubId,
      destinationHubId,
      pickupLocation,
      serviceTypeId,
    } = getValues();
    const valid =
      isValidItem(item) &&
      value &&
      destinationHubId &&
      serviceTypeId &&
      deliveryTypeId &&
      departureHubId &&
      (dId === "HD" ? pickupLocation : true);
    console.log({ isValid: valid });

    if (valid) {
      const body = {
        taxId: "VAT",
        insuranceId: "Insurance_2",
        packagingCostId: "ABC_test2",
        totalKilometers: undefined,
        deliveryTypeId: form.deliveryTypeId,
        customerTypeId: user?.customerTypeId || "Regular",
        departureHubId: form.departureHubId,
        destinationHubId: form.destinationHubId,
        serviceTypeId: form.serviceTypeId,
        amount: undefined,
        error: undefined,
        itemTypeId: item?.itemTypeId ? item.itemTypeId : "",
        declared: +item?.declared ? +item.declared : 0,
        quantity: +item?.quantity ? +item.quantity : 0,
        weight: +item?.weight ? +item.weight : 0,
        shipmentTypeId: item?.shipmentTypeId ? item.shipmentTypeId : "",
        [name]: value,
      };

      const data = await makeRequest({
        path: `/Orders/GetQuote`,
        method: "POST",
        body,
      });

      if (data.error) {
        for (let i = 0; i < controlledFields.length; i++) {
          setValue(`items.${i}.error`, data.error);
        }
      } else {
        const res = JSON.parse(data.data);

        for (let i = 0; i < controlledFields.length; i++) {
          setValue(`items.${i}.amount`, +res.amount);
        }
      }
    }
  };

  const onSelect = (type, value) => {
    if (type === "dep") {
      setDepartureState(value);
    } else {
      setArrivalState(value);
    }

    getHubs(value, type);
    check();
  };

  const onSelectHub = (type, value) => {
    const [address, id] = value.split("-----");

    if (type === "dep") {
      setValue("departureHubAdd", address);
      setValue("departureHubId", id);
    } else {
      setValue("destinationHubAdd", address);
      setValue("destinationHubId", id);
    }

    check();
  };

  useEffect(() => {
    if (statesStatus === "idle") {
      getStates();
    }
    if (shipmentTypesStatus === "idle") {
      getShipmentTypes();
    }
    if (deliveryTypesStatus === "idle") {
      getDeliveryTypes();
    }
    if (serviceTypesStatus === "idle") {
      getServiceTypes();
    }
    if (itemTypesStatus === "idle") {
      getItemTypes();
    }
  }, [
    getStates,
    getServiceTypes,
    getDeliveryTypes,
    getItemTypes,
    getShipmentTypes,
    serviceTypesStatus,
    deliveryTypesStatus,
    itemTypesStatus,
    statesStatus,
    shipmentTypesStatus,
  ]);

  const retry = () => {
    if (statesStatus === "rejected") {
      getStates();
    }
    if (shipmentTypesStatus === "rejected") {
      getShipmentTypes();
    }
    if (deliveryTypesStatus === "rejected") {
      getDeliveryTypes();
    }
    if (serviceTypesStatus === "rejected") {
      getServiceTypes();
    }
    if (itemTypesStatus === "rejected") {
      getItemTypes();
    }
  };

  const errorLoadingAnyDropDown =
    statesStatus === "rejected" ||
    shipmentTypesStatus === "rejected" ||
    deliveryTypesStatus === "rejected" ||
    serviceTypesStatus === "rejected" ||
    itemTypesStatus === "rejected";

  const onSubmit = async (data) => {
    const totalAmount = calculateTotal(controlledFields);

    if (user.balance < totalAmount) {
      toast.error("Insufficient funds. Please top up your wallet.");
      return;
    }

    try {
      setLoading(true);
      const cleanedItems = data.items.map(({ error, amount, ...item }) => ({
        ...item,
        itemTypeId: item.itemTypeId,
        itemDescription: item.itemDescription,
        itemName: "Delivery",
        statusId: "",
      }));
      const payload = {
        deliveryTypeId: data.deliveryTypeId,
        serviceTypeId: data.serviceTypeId,
        sourceId: "WEB",
        channelId: "WALLET",
        vehicleTypeId: "car_1",
        isViaThirdParty: false,
        taxId: data.taxId,
        items: cleanedItems,
        insuranceId: data.insuranceId,
        destinationHubId: data.destinationHubId,
        deliveryLocation: data.destinationHubAdd,
        originHubId: data.departureHubId,
        receiverBusinessName: data.receiverBusinessName,
        receiverEmail: data.receiverEmail,
        receiverFirstName: data.receiverFirstName,
        receiverLastName: data.receiverLastName,
        receiverPhoneNumber: data.receiverPhoneNumber.replace("+", ""),
        pickupLocation: data.pickupLocation,
      };
      console.log({ x: payload, y: data });
      const response = await makeRequest({
        url: "https://api.abccargoxpress.com/api/v2/Orders/CreateWaybill?api-version=2",
        method: "POST",
        body: payload,
      });

      if (!response.error) {
        const result = JSON.parse(response.data);
        console.log("Waybill created successfully:", result);
        toast.success("Order created successfully!");
        reset(bookInitValues);

        setTimeout(() => {
          navigate("/dashboard");
          window.location.reload();
        }, 3000);
      } else {
        const error = response.error || "Error booking waybill";
        console.error("Failed to create waybill:", error);
        toast.error(error);
      }
    } catch (error) {
      console.error("Error creating waybill:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleErrors = (...data) => {
    console.log(data);
  };

  return (
    <>
      <Container>
        <br />
        <Header center>Book a Waybill</Header>
        {loading ? (
          <Spinner2 />
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit, handleErrors)}
            className={classes.form}
            reset={reset}
          >
            <p> Please fill the form below to book a waybill</p>
            {errorLoadingAnyDropDown ? (
              <ButtonLink className={classes.retry} onClick={retry}>
                Failed to load some options. Click to retry.
              </ButtonLink>
            ) : null}

            <br />
            <div className={classes.Err}>
              {statesStatus === "rejected" ? (
                <p>States error : {statesErr}</p>
              ) : null}
            </div>

            <div className={classes.Err}>
              {departureStatus === "rejected" ? (
                <p>Departure Hub error :{departureErr}</p>
              ) : null}
            </div>
            <div className={classes["form-row"]}>
              <Inputs
                inputProps={{
                  ...register("deliveryTypeId", {
                    onChange: (e) => {
                      onChangeField(e);
                      clearFields();
                    },
                  }),
                  name: "deliveryTypeId",
                  fade: true,
                  options: deliveryTypes,
                }}
                type="select"
                label="Delivery Type"
                labelClass={classes.label}
                error={
                  (errors.deliveryTypeId && errors.deliveryTypeId?.message) ||
                  (deliveryTypesStatus === "rejected" && deliveryTypesErr)
                }
              />

              <Inputs
                inputProps={{
                  ...register("serviceTypeId", {
                    onChange: (e) => {
                      onChangeField(e);

                      if (departureState) {
                        setDepartureState("");
                      }
                      if (arrivalState) {
                        setArrivalState("");
                      }

                      clearFields();
                    },
                  }),
                  name: "serviceTypeId",
                  fade: true,
                  options: serviceTypes,
                }}
                type="select"
                label="Service Type"
                labelClass={classes.label}
                error={
                  (errors.serviceTypeId && errors.serviceTypeId?.message) ||
                  (serviceTypesStatus === "rejected" && serviceTypesErr)
                }
              />

              <SelectSearchDropDown
                label={<h4 className={classes.dropLabel}>Departure state</h4>}
                options={
                  statesStatus === "resolved" ? transformOptions(states) : []
                }
                search={true}
                name={"Pick"}
                value={departureState}
                disabled={
                  (statesStatus !== "resolved" && !!states.length) ||
                  statesStatus === "rejected" ||
                  statesStatus === "pending"
                }
                onChange={(value) => onSelect("dep", value)}
                placeholder={
                  statesStatus === "pending"
                    ? "Loading..."
                    : "Choose departure state..."
                }
              />
            </div>

            <br />

            <div className={classes.Err}>
              {arrivalStatus === "rejected" ? (
                <p>Arrival Hub error {arrivalErr}</p>
              ) : null}
            </div>

            <div className={classes["form-row"]}>
              <SelectSearchDropDown
                label={<h4 className={classes.dropLabel}>Departure hub</h4>}
                options={
                  departureStatus === "resolved" && !!departureHubs.length
                    ? transformHubs(departureHubs)
                    : []
                }
                search={true}
                name={"Pick"}
                value={`${watchFields[4]}-----${watchFields[1]}`}
                disabled={
                  (departureStatus === "resolved" && !departureHubs.length) ||
                  departureStatus === "rejected" ||
                  departureStatus === "pending"
                }
                onChange={(value) => onSelectHub("dep", value)}
                placeholder={
                  departureStatus === "pending"
                    ? "Loading..."
                    : "Choose departure hub..."
                }
              />

              <SelectSearchDropDown
                label={<h4 className={classes.dropLabel}>Arrival state</h4>}
                options={
                  statesStatus === "resolved" ? transformOptions(states) : []
                }
                search={true}
                name={"Pick"}
                value={arrivalState}
                disabled={
                  (statesStatus !== "resolved" && !!states.length) ||
                  statesStatus === "rejected" ||
                  statesStatus === "pending"
                }
                onChange={(value) => onSelect("arr", value)}
                placeholder={
                  statesStatus === "pending"
                    ? "Loading..."
                    : "Choose arrival state..."
                }
              />

              <SelectSearchDropDown
                label={<h4 className={classes.dropLabel}>Arrival hub</h4>}
                options={
                  arrivalStatus === "resolved" && !!arrivalHubs.length
                    ? transformHubs(arrivalHubs)
                    : []
                }
                search={true}
                name={"Pick"}
                value={`${watchFields[5]}-----${watchFields[2]}`}
                disabled={
                  (arrivalStatus === "resolved" && !arrivalHubs.length) ||
                  arrivalStatus === "rejected" ||
                  arrivalStatus === "pending"
                }
                onChange={(value) => onSelectHub("arr", value)}
                placeholder={
                  arrivalStatus === "pending"
                    ? "Loading..."
                    : "Choose arrival hub..."
                }
              />
            </div>
            <div className={classes["form-row"]}>
              <Inputs
                type="text"
                label="Receiver first Name"
                labelClass={classes.label}
                error={errors.receiverFirstName?.message}
                inputProps={{
                  ...register("receiverFirstName", {
                    required: "First name is required",
                    minLength: {
                      value: 2,
                      message: "Must be at least 2 characters",
                    },
                  }),
                }}
              />
              <Inputs
                type="text"
                label="Receiver last Name"
                labelClass={classes.label}
                error={errors.receiverLastName?.message}
                inputProps={{
                  ...register("receiverLastName", {
                    required: "Last name is required",
                    minLength: {
                      value: 2,
                      message: "Must be at least 2 characters",
                    },
                  }),
                }}
              />

              <Controller
                name="receiverPhoneNumber"
                control={control}
                render={({ field }) => (
                  <Inputs
                    field={field}
                    type="tel"
                    inputProps={{
                      name: "receiverPhoneNumber",
                    }}
                    label="Receiver Phone"
                    labelClass={classes.label}
                    error={
                      errors.receiverPhoneNumber &&
                      errors.receiverPhoneNumber?.message
                    }
                  />
                )}
              />
            </div>
            <div className={classes["form-row"]}>
              <Inputs
                inputProps={{
                  ...register("receiverEmail"),
                  name: "receiverEmail",
                }}
                label="Receiver E-mail"
                type="email"
                labelClass={classes.label}
                error={errors.receiverEmail && errors.receiverEmail?.message}
              />
              <Inputs
                type="text"
                label="Business Name"
                labelClass={classes.label}
                error={errors.receiverBusinessName?.message}
                inputProps={{
                  ...register("receiverBusinessName", {
                    required: "receiver Business Name is required",
                    minLength: {
                      value: 2,
                      message: "Must be at least 2 characters",
                    },
                  }),
                }}
              />
            </div>

            {dId === "HD" ? (
              <Inputs
                inputProps={{
                  ...register("pickupLocation", {
                    onChange: onChangeField,
                  }),
                  name: `pickupLocation`,
                }}
                label="Delivery Address"
                labelClass={classes.label}
                error={errors?.["pickupLocation"]?.["message"]}
              />
            ) : null}

            <br />

            <h3 className={classes.items}>Item</h3>
            <hr />

            <br />

            {fields.length < 1 ? (
              <div className={classes.Err}>
                <small>Please add at least one item</small>
              </div>
            ) : null}
            {controlledFields.map((item, index) => (
              <div key={item.id}>
                <div className={classes["item-row"]}>
                  <Inputs
                    label="Shipment Type"
                    type="select"
                    inputProps={{
                      ...register(`items.${index}.shipmentTypeId`, {
                        onChange: (e) => {
                          onChange(e, index);
                        },
                      }),
                      name: `items.${index}.shipmentTypeId`,
                      fade: true,
                      options: shipmentTypes,
                    }}
                    error={
                      errors?.["items"]?.[index]?.["shipmentTypeId"]?.[
                        "message"
                      ] ||
                      (shipmentTypesStatus === "rejected" && shipmentTypesErr)
                    }
                    labelClass={classes.label}
                  />

                  <Inputs
                    inputProps={{
                      ...register(`items.${index}.declared`, {
                        onChange: (e) => onChange(e, index),
                      }),
                      name: `items.${index}.declared`,
                    }}
                    wrapperClass={classes.num2}
                    type="number"
                    label="Declared value"
                    labelClass={classes.label}
                    error={
                      errors?.["items"]?.[index]?.["declared"]?.["message"]
                    }
                  />

                  <Inputs
                    inputProps={{
                      ...register(`items.${index}.weight`, {
                        onChange: (e) => onChange(e, index),
                      }),
                      name: `items.${index}.weight`,
                    }}
                    wrapperClass={classes.num}
                    type="number"
                    label="Weight (in kg)"
                    labelClass={classes.label}
                    error={errors?.["items"]?.[index]?.["weight"]?.["message"]}
                  />

                  <Inputs
                    wrapperClass={classes.num}
                    inputProps={{
                      ...register(`items.${index}.quantity`, {
                        onChange: (e) => onChange(e, index),
                      }),
                      name: `items.${index}.quantity`,
                    }}
                    type="number"
                    label="Quantity"
                    labelClass={classes.label}
                    error={
                      errors?.["items"]?.[index]?.["quantity"]?.["message"]
                    }
                  />

                  {/* <button
                                          type="button"
                                          className={ classes.get }
                                          disabled={ !isValidItem( item ) || !validWatchFields( watchFields ) }
                                          onClick={ () => getQuote( item, index ) }
                                      >
                                          Get quote
                                      </button> */}

                  {/* <button
                    className={classes.remove}
                    type="button"
                    onClick={() => remove(index)}
                  >
                    <i className="lar la-times-circle"></i>
                  </button> */}
                </div>
                <div className={classes["item-row"]}>
                  <Inputs
                    type="textarea"
                    label="Item Description"
                    labelClass={classes.label}
                    inputProps={{
                      ...register(`items.${index}.itemDescription`, {
                        onChange: (e) => onChange(e, index),
                      }),
                      name: `items.${index}.itemDescription`,
                    }}
                    error={
                      errors?.["items"]?.[index]?.["itemDescription"]?.[
                        "message"
                      ]
                    }
                  />
                </div>
                {item.error ? (
                  <div className={classes.Err}>
                    <small>{item.error}</small>
                  </div>
                ) : null}
              </div>
            ))}

            <br />

            {/* <button
              type="button"
              className={classes.append}
              onClick={() => append({ ...item })}
            >
              ADD
            </button> */}

            <br />

            <br />

            <div className={classes.total}>
              <h3>Total</h3>

              <h3>{formatCurrency(calculateTotal(controlledFields))}</h3>
            </div>
            <h3 className={classes.current}>
              {" "}
              Current Wallet Balance : {formatCurrency(user.balance)}{" "}
            </h3>

            <button type="submit" className={classes.append}>
              Pay with wallet
            </button>
          </form>
        )}
      </Container>
    </>
  );
};

export default BookWaybill;
