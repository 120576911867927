import React, { useState } from "react";
import classNames from "classnames";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";

import styles from "./Inputs.module.scss";

const Inputs = ({
  type,
  label,
  labelClass,
  wrapperClass,
  errorClass,
  inputClass,
  errorProps,
  error,
  inputProps,
  field,
  showErr = true,
  wrapperProps,
}) => {
  const [show, setShow] = useState(false);

  const togglePwd = () => {
    setShow((s) => !s);
  };

  switch (type) {
    case "select":
      const { options, name, fade, ignoreDummy, selectText, ...p } = inputProps;

      return (
        <div className={classNames(styles.fieldGrp, wrapperClass)}>
          {label ? (
            <label className={labelClass} htmlFor={inputProps.name}>
              {label}
            </label>
          ) : null}

          <select id={name} className={inputClass} name={name} {...p}>
            {!ignoreDummy ? (
              <option
                className={classNames({
                  [styles.fadeOpt]: fade,
                })}
                value=""
              >
                {selectText || "Select..."}
              </option>
            ) : null}

            {options.map((p) => (
              <option data-extra={p.extra} key={p.id} value={p.val}>
                {p.label}
              </option>
            ))}
          </select>
          {showErr ? (
            <small className={errorClass} {...errorProps}>
              {error}
            </small>
          ) : null}
        </div>
      );

    case "tel":
      return (
        <div className={classNames(styles.fieldGrp, wrapperClass)}>
          {label ? (
            <label className={labelClass} htmlFor={inputProps.name}>
              {label}
            </label>
          ) : null}
          <div className={styles.input}>
            <PhoneInput
              {...field}
              international
              defaultCountry="NG"
              value={formatPhoneNumberIntl(`+${field.value}`)}
              countryCallingCodeEditable={false}
              placeholder="Enter phone number"
            />
          </div>

          {showErr ? (
            <small className={errorClass} {...errorProps}>
              {error}
            </small>
          ) : null}
        </div>
      );

    case "text":
      return (
        <div
          className={classNames(styles.fieldGrp, wrapperClass)}
          {...wrapperProps}
        >
          {label && (
            <label className={labelClass} htmlFor={inputProps.name}>
              {label}
            </label>
          )}
          <input type="text" className={inputClass} {...inputProps} />
          {showErr && (
            <small className={errorClass} {...errorProps}>
              {error}
            </small>
          )}
        </div>
      );
    case "email":
      return (
        <div
          className={classNames(styles.fieldGrp, wrapperClass)}
          {...wrapperProps}
        >
          {label && (
            <label className={labelClass} htmlFor={inputProps.name}>
              {label}
            </label>
          )}
          <input type="email" className={inputClass} {...inputProps} />
          {showErr && (
            <small className={errorClass} {...errorProps}>
              {error}
            </small>
          )}
        </div>
      );

    case "textarea":
      return (
        <div
          className={classNames(styles.fieldGrp, wrapperClass)}
          {...wrapperProps}
        >
          {label ? (
            <label className={labelClass} htmlFor={inputProps.name}>
              {label}
            </label>
          ) : null}

          <textarea
            className={inputClass}
            {...inputProps}
            cols="40"
            rows="5"
          ></textarea>

          {showErr ? (
            <small className={errorClass} {...errorProps}>
              {error}
            </small>
          ) : null}
        </div>
      );

    case "password":
      return (
        <div
          className={classNames(styles.fieldGrp, wrapperClass)}
          {...wrapperProps}
        >
          {label ? (
            <label className={labelClass} htmlFor={inputProps.name}>
              {label}
            </label>
          ) : null}

          <input
            type={show ? "text" : "password"}
            className={classNames(styles.pwd, inputClass)}
            {...inputProps}
          />

          {showErr ? (
            <small className={errorClass} {...errorProps}>
              {error}
            </small>
          ) : null}

          <button type="button" className={styles.showPwd} onClick={togglePwd}>
            {show ? (
              <i className="las la-eye-slash"></i>
            ) : (
              <i className="lar la-eye"></i>
            )}
          </button>
        </div>
      );

    default:
      return (
        <div
          className={classNames(styles.fieldGrp, wrapperClass)}
          {...wrapperProps}
        >
          {label ? (
            <label className={labelClass} htmlFor={inputProps.name}>
              {label}
            </label>
          ) : null}

          <input type={type || "text"} className={inputClass} {...inputProps} />

          {showErr ? (
            <small className={errorClass} {...errorProps}>
              {error}
            </small>
          ) : null}
        </div>
      );
  }
};

export { Inputs };
