import { ordersConstants } from "../constants";

import { request, failure } from "../../utils/helpers";

const initialState = {
  loading: false,
  orders: [],
  error: null,
  status: "idle",
  customerNet: [],
  customerNetStatus: "idle",
  customerNetErr: null,
  collections: [],
  collStatus: "idle",
  collErr: null,
};

export function orders(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ordersConstants.FETCH_USER_ORDERS_REQUEST:
      return request(state);

    case ordersConstants.FETCH_USER_COLL_ORDERS_REQUEST:
      return {
        ...state,
        collStatus: "pending",
        collErr: null,
      };
    case ordersConstants.FETCH_CUSTOMER_ACCOUNT_REPORT_REQUEST:
      return {
        ...state,
        customerNetStatus: "pending",
        customerNetErr: null,
      };

    case ordersConstants.FETCH_USER_ORDERS_FAILURE:
      return failure(state, action.payload);

    case ordersConstants.FETCH_USER_COLL_ORDERS_FAILURE:
      return {
        ...state,
        collStatus: "rejected",
        collErr: payload,
      };
    case ordersConstants.FETCH_CUSTOMER_ACCOUNT_REPORT_FAILURE:
      return {
        ...state,
        customerNetStatus: "rejected",
        customerNetErr: payload,
      };
    case ordersConstants.FETCH_USER_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: payload,
        status: "resolved",
      };

    case ordersConstants.FETCH_USER_COLL_ORDERS_SUCCESS:
      return {
        ...state,
        collStatus: "resolved",
        collections: payload,
      };
    case ordersConstants.FETCH_CUSTOMER_ACCOUNT_REPORT_SUCCESS:
      return {
        ...state,
        customerNetStatus: "resolved",
        customerNet: payload,
      };

    default:
      return state;
  }
}
