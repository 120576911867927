import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Paginate from "./Paginate";
import styles from "./Table.module.scss";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import { matchSorter } from "match-sorter";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  //const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className={styles.search}>
      <span>
        Search:{" "}
        <input
          value={value || ""}
          type="search"
          className={styles.searchBg}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Waybills...`}
          style={{
            fontSize: "1.1rem",
            border: "0",
          }}
        />
      </span>
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

const Table = ({
  columns,
  data,
  bordered,
  useSearch = false,
  //bordered=true
  wrapperClass,
  rowClickHandler = false,
}) => {
  // const filterTypes = React.useMemo(
  //   () => ({
  //     fuzzyText: fuzzyTextFilterFn,
  //   }),
  //   []
  // );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination
  );

  const [paginate, setPaginate] = useState(false);
  const limit = 10;

  useEffect(() => {
    if (data.length > limit) {
      setPaginate(true);
    }
  }, [limit, data]);
  return (
    <div className={classNames(styles.wrapper, wrapperClass)}>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <div style={{ overflowX: "auto", width: "100%" }}>
        <table
          className={classNames({ [styles.useBorder]: bordered })}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className={classNames({ [styles.rowClick]: rowClickHandler })}
                  onClick={() =>
                    rowClickHandler ? rowClickHandler(row.original) : null
                  }
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {paginate ? (
        <Paginate
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          previousPage={previousPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          setPageSize={setPageSize}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export { Table };
