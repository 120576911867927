import React, { useRef } from "react";
import {
  Container,
  DashboardHeader,
  DateFromTo,
  EmptyState,
  Retry,
  Spinner1,
} from "../../../components";
import { formatCurrency, formatDate } from "../../../utils/helpers";
import { CustomerNetTable } from "../../../components/Tables/CustomerNetTable";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ordersActions } from "../../../redux/actions";
import { PrintTable } from "../../../components/Table/PrintTable";

const CustomerNet = () => {
  const dispatch = useDispatch();

  const { customerNet, customerNetStatus, customerNetErr } = useSelector(
    (state) => state.orders
  );
  const cols = [
    {
      Header: "Waybill no",
      accessor: "orderId",
    },
    {
      Header: "Customer name",
      accessor: "customerName",
    },
    {
      Header: "Receiver name",
      accessor: "recieverName",
    },
    {
      Header: "Origin Hub",
      accessor: "departureHub",
    },
    {
      Header: "Destination Hub",
      accessor: "destinationHub",
    },

    {
      Header: "Number of Items",
      accessor: "numberOfItems",
    },
    {
      Header: "Total Weight",
      accessor: "totalWeight",
    },
    {
      Header: "Freight Price",
      accessor: "freightPrice",
      Cell: ({ cell }) => formatCurrency(cell.value),
    },
    {
      Header: "Insurance Price",
      accessor: "insurancePrice",
      Cell: ({ cell }) => formatCurrency(cell.value),
    },
    {
      Header: "VAT Price",
      accessor: "vatPrice",
      Cell: ({ cell }) => formatCurrency(cell.value),
    },
    {
      Header: "Packaging Cost",
      accessor: "packagingCost",
      Cell: ({ cell }) => formatCurrency(cell.value),
    },
    {
      Header: "Total Charges",
      accessor: "totalCharges",
      Cell: ({ cell }) => formatCurrency(cell.value),
    },
    {
      Header: "Net Amount",
      accessor: "netAmount",
      Cell: ({ cell }) => formatCurrency(cell.value),
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Booking Date",
      accessor: "bookingDate",
      Cell: ({ cell }) => formatDate(cell.value),
    },
    {
      Header: "Date Delivered",
      accessor: "dateDelivered",
    },
    {
      Header: "Date Arrived",
      accessor: "dateArrived",
    },
    {
      Header: "Date Assigned",
      accessor: "dateAssigned",
    },
  ];

  const { getCustomerAccountReport } = bindActionCreators(
    ordersActions,
    dispatch
  );
  const dateFrom = useRef("");
  const dateTo = useRef("");
  const getTransactions = () => {
    getCustomerAccountReport({
      dateFrom: dateFrom.current,
      dateTo: dateTo.current,
    });
    console.log(dateFrom.current, dateTo.current);
  };

  const retry = () => {
    getCustomerAccountReport();
  };

  const onStartDateChange = (date) => {
    dateFrom.current = formatDate(date, "yyyy/LL/dd");
  };

  const onEndDateChange = (date) => {
    dateTo.current = formatDate(date, "yyyy/LL/dd");
  };

  // console.log({ customerNet, customerNetStatus });

  return (
    <Container>
      <DashboardHeader pageTitle="Customer Net" />
      <DateFromTo
        go={getTransactions}
        onEndDateChange={onEndDateChange}
        onStartDateChange={onStartDateChange}
      />
      {customerNetStatus === "resolved" && !customerNet.length ? (
        <EmptyState text="No orders available for this date range." />
      ) : null}
      {customerNetStatus === "resolved" && customerNet.length ? (
        <>
          <CustomerNetTable data={customerNet} />
          <PrintTable
            data={customerNet}
            columns={cols}
            headerText={
              <h1>
                Orders {dateFrom.current ? `from ${dateFrom.current}` : ""}{" "}
                {dateTo.current ? `to ${dateTo.current}` : ""}
              </h1>
            }
          />
        </>
      ) : null}

      {customerNetStatus === "pending" ? <Spinner1 /> : null}

      {customerNetStatus === "rejected" ? (
        <>
          <p>{customerNetErr}</p>
          <Retry retry={retry} />
        </>
      ) : null}
    </Container>
  );
};

export default CustomerNet;
