import { userConstants } from "../constants";
import { makeRequest } from "../../utils/makeRequest";
import { dispatcher } from "../../utils/helpers";

const topWalletStart = () => async (dispatch) => {
  dispatch(dispatcher(userConstants.TOP_WALLET_USER_REQUEST));
};

const topWalletFail = (error) => async (dispatch) => {
  dispatch(dispatcher(userConstants.TOP_WALLET_USER_FAILURE, error));
};

const topWalletSuccess = (amount) => async (dispatch) => {
  dispatch(dispatcher(userConstants.TOP_WALLET_USER_SUCCESS, +amount));
};

const getUserById = (customerId) => async (dispatch) => {
  dispatch(dispatcher(userConstants.FETCH_USER_REQUEST));
  const data = await makeRequest({
    path: `Customers/ViewCustomerById?customerId=${customerId}`,
  });
  if (data.error) {
    dispatch(dispatcher(userConstants.FETCH_USER_FAILURE, data.error));
  } else {
    console.log({ data2: data });
    const res = JSON.parse(data?.data);

    const payload = {
      customerId,
      userName: res.aspNetUser.userName,
      firstName: res.aspNetUser.firstName,
      lastName: res.aspNetUser.lastName,
      email: res.aspNetUser.email,
      phoneNumber: res.aspNetUser.phoneNumber,
      balance: res.balance,
      defaultPickupLocation: res.defaultPickupLocation,
      businessName: res.businessName,
      customerTypeId: res.customerTypeId,
    };
    console.log({ x: payload });
    dispatch(dispatcher(userConstants.FETCH_USER_SUCCESS, payload));
  }
};

const updateUser = (body) => async (dispatch) => {
  dispatch(dispatcher(userConstants.UPDATE_USER_REQUEST));
  const data = await makeRequest({
    path: `/Customers/UpdateCustomerAccount`,
    method: "POST",
    body,
  });

  if (data.error) {
    dispatch(dispatcher(userConstants.UPDATE_USER_FAILURE, data.error));
  } else {
    const res = JSON.parse(data.data);

    const payload = {
      defaultPickupLocation: res.defaultPickupLocation,
      userName: res.aspNetUser.userName,
      firstName: res.aspNetUser.firstName,
      lastName: res.aspNetUser.lastName,
      email: res.aspNetUser.email,
      phoneNumber: res.aspNetUser.phoneNumber,
      balance: res.balance,
      businessName: res.businessName,
      customerTypeId: res.customerTypeId,
    };

    dispatch(dispatcher(userConstants.UPDATE_USER_SUCCESS, payload));
  }
};

const setStatus =
  ({ key, value, errKey, errValue }) =>
  async (dispatch) => {
    dispatch(
      dispatcher(userConstants.SET_STATUS, { key, value, errKey, errValue })
    );
  };

export const userActions = {
  topWalletFail,
  topWalletStart,
  topWalletSuccess,
  getUserById,
  updateUser,
  setStatus,
};
