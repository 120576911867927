import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  Container,
  Header,
  Inputs,
  ButtonLink,
  FormContainer,
  ContactSection,
} from "@components";

import { authActions, userActions, hubActions } from "@redux/actions";

import { checkLogin } from "@utils";

import styles from "../Page.module.scss";
import { makeRequest } from "../../../fromOld/utils/makeRequest";

const schema = yup
  .object()
  .shape({
    userName: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

const hasError = (errors) => {
  return errors?.password?.message || errors?.userName?.message;
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { status, error, user } = useSelector((state) => state.auth);
  const { getUserStatus } = useSelector((state) => state.user);
  const { user: user2 } = useSelector((state) => state);
  const sendOTP = async (phoneNumber) => {
    try {
      const response = await makeRequest({
        url: `https://api.abccargoxpress.com/api/v2/Auth/send-otp?api-version=2&phoneNumber=${phoneNumber}`,
        method: "POST",
      });
      console.log("OTP Sent:", response);
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const { login } = bindActionCreators(authActions, dispatch);

  const onSubmit = ({ userName, password }) => {
    console.log("hello");
    login(userName, password);
  };

  useEffect(() => {
    if (!user.token) {
      const abcUser = localStorage.getItem("abc-user");

      if (checkLogin() && abcUser) {
        dispatch(authActions.setUser(JSON.parse(abcUser)));
        dispatch(hubActions.getHubs());
      }
    }

    if (user?.token || status === "resolved") {
      if (getUserStatus === "idle") {
        dispatch(userActions.getUserById(user.id));
      }
    }

    console.log({
      getUserStatus,
      user2,
    });

    if (getUserStatus === "resolved") {
      if (user2?.phoneNumberConfirmed === false) {
        // sendOTP(user2.phoneNumber);
        navigate("/dashboard");
      } else {
        navigate("/dashboard");
      }
    }
  }, [dispatch, status, user2, user, getUserStatus, navigate]);

  return (
    <>
      <Container>
        <br />

        <Header center>Login</Header>

        <FormContainer
          message={`Welcome customer`}
          submitHandler={handleSubmit(onSubmit)}
          formClass={styles.formClass}
        >
          {error && (
            <>
              <br />
              <p className={styles.formErr}>{error}</p>
            </>
          )}

          <br />

          <Inputs
            inputProps={{
              ...register("userName"),
              name: "userName",
            }}
            label="Username"
            labelClass={styles.label}
            error={errors.userName && errors.userName?.message}
          />

          <small className={styles.info}>
            If phone number is used to sign in, please include country code
            (without +), excluding the first zero, eg 2348139862090
          </small>

          <br />

          <Inputs
            inputProps={{
              ...register("password"),
              name: "password",
            }}
            type="password"
            label="Password"
            labelClass={styles.label}
            error={errors.password && errors.password?.message}
          />

          <ButtonLink
            disabled={status === "pending" || hasError(errors)}
            className={styles.submit}
          >
            Sign in
          </ButtonLink>

          <br />

          <p>
            No account ? &nbsp;
            <NavLink className={styles.reg} to="/sign-up">
              Sign up
            </NavLink>
          </p>

          <br />

          <p style={{ textAlign: "right" }}>
            Forgot password ? &nbsp;
            <NavLink className={styles.reg} to="/reset-password">
              Reset password
            </NavLink>
          </p>
        </FormContainer>
      </Container>

      <ContactSection />
    </>
  );
};

export default Login;
