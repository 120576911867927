export const ordersConstants = {
  FETCH_USER_ORDERS_REQUEST: "FETCH_USER_ORDERS_REQUEST",
  FETCH_USER_ORDERS_SUCCESS: "FETCH_USER_ORDERS_SUCCESS",
  FETCH_USER_ORDERS_FAILURE: "FETCH_USER_ORDERS_FAILURE",

  FETCH_CUSTOMER_ACCOUNT_REPORT_REQUEST:
    "FETCH_CUSTOMER_ACCOUNT_REPORT_REQUEST",
  FETCH_CUSTOMER_ACCOUNT_REPORT_FAILURE:
    "FETCH_CUSTOMER_ACCOUNT_REPORT_FAILURE",
  FETCH_CUSTOMER_ACCOUNT_REPORT_SUCCESS:
    "FETCH_CUSTOMER_ACCOUNT_REPORT_SUCCESS",

  FETCH_USER_COLL_ORDERS_REQUEST: "FETCH_USER_COLL_ORDERS_REQUEST",
  FETCH_USER_COLL_ORDERS_SUCCESS: "FETCH_USER_COLL_ORDERS_SUCCESS",
  FETCH_USER_COLL_ORDERS_FAILURE: "FETCH_USER_COLL_ORDERS_FAILURE",
};
