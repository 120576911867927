import * as yup from "yup";
import { isPossiblePhoneNumber } from "react-phone-number-input";

function isValidPhone(message = "Please input a valid phone number") {
  return this.test("isValidPhone", message, function (value) {
    const { path, createError } = this;

    const num = value[0] === "+" ? value : `+${value}`;

    if (value && !isPossiblePhoneNumber(num)) {
      return createError({ path, message });
    }

    return true;
  });
}
yup.addMethod(yup.mixed, "isValidPhone", isValidPhone);

const item = {
  itemTypeId: "",
  declared: 1,
  quantity: 1,
  weight: 1,
  amount: "",
  error: "",
  shipmentTypeId: "",
};

const initValues = {
  taxId: "VAT",
  insuranceId: "Insurance_2",
  packagingCostId: "ABC_test2",
  //totalKilometers: "",
  deliveryTypeId: "",
  departureHubAdd: "",
  destinationHubAdd: "",
  pickupLocation: "",
  customerTypeId: "Regular",
  items: [{ ...item }],
  departureHubId: "",
  destinationHubId: "",
  serviceTypeId: "",
};

const bookInitValues = {
  taxId: "VAT",
  insuranceId: "Insurance_2",
  packagingCostId: "ABC_test2",
  //totalKilometers: "",
  deliveryTypeId: "",
  departureHubAdd: "",
  destinationHubAdd: "",
  pickupLocation: "",
  customerTypeId: "Regular",
  items: [{ ...item }],
  departureHubId: "",
  destinationHubId: "",
  serviceTypeId: "",
  receiverFirstName: "",
  receiverPhoneNumber: "",
  receiverLastName: "",
  receiverEmail: "",
  receiverBusinessName: "",
};
const bookWaybillSchema = yup
  .object()
  .shape({
    taxId: yup.string().required("Tax is required"),
    insuranceId: yup.string().required("Insurance is required"),
    packagingCostId: yup.string().required("packagingCost is required"),
    deliveryTypeId: yup.string().required("deliveryTypeId is required"),
    customerTypeId: yup.string().required("customerTypeId is required"),
    items: yup.array().of(
      yup.object().shape({
        declared: yup
          .number()
          .typeError("Please enter a valid number")
          .required("Declared is required"),
        // itemTypeId: yup.string().required("itemTypeId is required"),
        quantity: yup
          .number()
          .typeError("Please enter a valid number")
          .required("Quantity is required"),
        weight: yup
          .number()
          .typeError("Please enter a valid number")
          .required("Weight is required"),
        itemDescription: yup.string().required("Item Description is required"),
        shipmentTypeId: yup.string().required("shipmentTypeId is required"),
      })
    ),
    departureHubId: yup.string().required("departureHubId is required"),
    destinationHubId: yup.string().required("destinationHubId is required"),
    serviceTypeId: yup.string().required("serviceTypeId is required"),
    receiverFirstName: yup.string().required("First name is required"),
    receiverLastName: yup.string().required("Last name is required"),
    receiverEmail: yup
      .string()
      .notRequired()
      .email("Please input a valid email"),
    receiverPhoneNumber: yup.string().required().isValidPhone(),
    receiverBusinessName: yup.string().nullable().notRequired(),
    pickupLocation: yup.string().nullable().notRequired(),
  })
  .required();

const schema = yup
  .object()
  .shape({
    taxId: yup.string().required("Tax is required"),
    insuranceId: yup.string().required("Insurance is required"),
    pickupLocation: yup.string().required("pickupLocation is required"),
    // totalKilometers: yup.number()
    //     .typeError( "Please enter a valid integer number > 0" )
    //     .required( "totalKilometers is required" ),
    packagingCostId: yup.string().required("packagingCost is required"),
    deliveryTypeId: yup.string().required("deliveryTypeId is required"),
    customerTypeId: yup.string().required("customerTypeId is required"),
    items: yup.array().of(
      yup.object().shape({
        declared: yup
          .number()
          .typeError("Please enter a valid number")
          .required("Declared is required"),
        itemTypeId: yup.string().required("itemTypeId is required"),
        quantity: yup
          .number()
          .typeError("Please enter a valid number")
          .required("Quantity is required"),
        weight: yup
          .number()
          .typeError("Please enter a valid number")
          .required("Weight is required"),
        shipmentTypeId: yup.string().required("shipmentTypeId is required"),
      })
    ),
    departureHubId: yup.string().required("departureHubId is required"),
    destinationHubId: yup.string().required("destinationHubId is required"),
    serviceTypeId: yup.string().required("serviceTypeId is required"),
  })
  .required();

export { schema, initValues, item, bookInitValues, bookWaybillSchema };
