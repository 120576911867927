import { quoteConstants } from "../constants";
import { makeRequest } from "../../utils/makeRequest";
import { dispatcher, trimHubs } from "../../utils/helpers";

const trimItemTypes = (items) => {
  return items.filter(
    (o) =>
      o.name.toLowerCase().includes("cargo") ||
      o.name.toLowerCase().includes("parcel")
  );
};

const trimDelivery = (items) => {
  return items.filter(
    (o) =>
      o.id.toLowerCase().includes("hd") || o.id.toLowerCase().includes("hh")
  );
};

const mapListForDropDownOrSelect = (list, valueKey = "id") => {
  return list.map((o) => ({
    ...o,
    label: o.id === "LMD" ? "Same City" : o.name,
    value: o.id,
    val: o[valueKey],
    extra: o,
  }));
};

const getHubs = (stateId, type) => async (dispatch) => {
  if (type === "dep") {
    dispatch(dispatcher(quoteConstants.FETCH_DEP_HUBS_REQUEST));

    const data = await makeRequest({
      path: `/Hubs/ViewHubsByState?StateId=${stateId}`,
      method: "GET",
    });

    if (data.error) {
      dispatch(dispatcher(quoteConstants.FETCH_DEP_HUBS_FAILURE, data.error));
    } else {
      const res = JSON.parse(data.data);

      dispatch(
        dispatcher(quoteConstants.FETCH_DEP_HUBS_SUCCESS, trimHubs(res))
      );
    }
  } else {
    dispatch(dispatcher(quoteConstants.FETCH_ARR_HUBS_REQUEST));

    const data = await makeRequest({
      path: `/Hubs/ViewHubsByState?StateId=${stateId}`,
      method: "GET",
    });

    if (data.error) {
      dispatch(dispatcher(quoteConstants.FETCH_ARR_HUBS_FAILURE, data.error));
    } else {
      const res = JSON.parse(data.data);

      dispatch(
        dispatcher(quoteConstants.FETCH_ARR_HUBS_SUCCESS, trimHubs(res))
      );
    }
  }
};

const getServiceTypes = () => async (dispatch) => {
  dispatch(dispatcher(quoteConstants.FETCH_SERVICE_TYPES_REQUEST));

  const data = await makeRequest({
    path: `/ServiceTypes/ViewAllServiceTypes`,
  });

  if (data.error) {
    dispatch(
      dispatcher(quoteConstants.FETCH_SERVICE_TYPES_FAILURE, data.error)
    );
  } else {
    let res = JSON.parse(data.data);
    res = res.filter((s) => !s.name.toLowerCase().includes("express"));

    dispatch(
      dispatcher(
        quoteConstants.FETCH_SERVICE_TYPES_SUCCESS,
        mapListForDropDownOrSelect(res)
      )
    );
  }
};

const getDeliveryTypes = () => async (dispatch) => {
  dispatch(dispatcher(quoteConstants.FETCH_DELIVERY_TYPES_REQUEST));

  const data = await makeRequest({
    path: `/DeliveryTypes/ViewAllDeliveryTypes`,
  });

  if (data.error) {
    dispatch(
      dispatcher(quoteConstants.FETCH_DELIVERY_TYPES_FAILURE, data.error)
    );
  } else {
    const res = JSON.parse(data.data);

    dispatch(
      dispatcher(
        quoteConstants.FETCH_DELIVERY_TYPES_SUCCESS,
        mapListForDropDownOrSelect(trimDelivery(res))
      )
    );
  }
};

const setStatus = (key, value) => ({
  type: quoteConstants.SET_STATUS,
  payload: {
    key,
    value,
  },
});

const getItemTypes = () => async (dispatch) => {
  dispatch(dispatcher(quoteConstants.FETCH_ITEM_TYPES_REQUEST));

  const data = await makeRequest({
    path: `ItemTypes/ViewAllItemTypes`,
    method: "GET",
  });

  if (data.error) {
    dispatch(dispatcher(quoteConstants.FETCH_ITEM_TYPES_FAILURE, data.error));
  } else {
    const res = JSON.parse(data.data);

    dispatch(
      dispatcher(
        quoteConstants.FETCH_ITEM_TYPES_SUCCESS,
        mapListForDropDownOrSelect(trimItemTypes(res))
      )
    );
  }
};

const getShipmentTypes = () => async (dispatch) => {
  dispatch(dispatcher(quoteConstants.FETCH_SHIPMENT_TYPES_REQUEST));

  const data = await makeRequest({
    path: `/ShipmentTypes/ViewAllShipmentTypes`,
    method: "GET",
  });

  if (data.error) {
    dispatch(
      dispatcher(quoteConstants.FETCH_SHIPMENT_TYPES_FAILURE, data.error)
    );
  } else {
    const res = JSON.parse(data.data);

    dispatch(
      dispatcher(
        quoteConstants.FETCH_SHIPMENT_TYPES_SUCCESS,
        mapListForDropDownOrSelect(trimItemTypes(res))
      )
    );
  }
};

export const quoteActions = {
  setStatus,
  getHubs,
  getServiceTypes,
  getDeliveryTypes,
  getItemTypes,
  getShipmentTypes,
};
