import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import { ButtonLink } from "../../../components/UI";
import styles from "./VerifyOtp.module.scss";
import classes from "./VerifyOtp.module.scss";

import OTPInput from "react-otp-input";
import { useSelector } from "react-redux";
import { makeRequest } from "../../utils/makeRequest";
import { useNavigate } from "react-router-dom";

const VerifyOtp = () => {
  const [otp, setOtp] = useState("");
  const [isResending, setIsResending] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user: user2 } = useSelector((state) => state);
  const { status, error, user } = useSelector((state) => state.auth);

  const sendOTP = async (phoneNumber) => {
    try {
      const response = await makeRequest({
        url: `https://api.abccargoxpress.com/api/v2/Auth/send-otp?api-version=2&phoneNumber=${phoneNumber}`,
        method: "POST",
      });
      console.log("OTP Sent:", response);
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleVerify = async () => {
    setLoading(true);
    try {
      const response = await makeRequest({
        url: "https://api.abccargoxpress.com/api/v2/Auth/verify-otp?api-version=2",
        method: "POST",
        body: { phoneNumber: user2.phoneNumber, otp },
        headers: { "Content-Type": "application/json" },
      });

      console.log("OTP verified successfully!", response);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("Invalid OTP. Please try again.");
    }
    setLoading(false);
  };

  const handleResendOtp = async () => {
    setIsResending(true);
    console.log({ amet: user2 });
    sendOTP(user2.phoneNumber);
    setCountdown(30);
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setIsResending(false);
    }
  }, [countdown]);
  return (
    <div className={classes["verify-otp-container"]}>
      <Header>Please verify your Phone Number</Header>
      <p className={classes["otp-message"]}>
        An OTP has been sent to your registered phone number.
      </p>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        isInputNum
        containerStyle={classes["otp-input-container"]}
        inputStyle={classes["otp-input"]}
        renderInput={(props) => <input {...props} />}
      />
      <ButtonLink onClick={handleVerify} disabled={otp.length !== 6 || loading}>
        Verify OTP
      </ButtonLink>
      <button
        className={classes["resend-btn"]}
        onClick={handleResendOtp}
        disabled={isResending}
      >
        {isResending
          ? `Try again in ${countdown}s`
          : "Didn't get any OTP? Retry"}
      </button>
    </div>
  );
};

export default VerifyOtp;
