import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./ConfirmNomba.module.scss";

const ConfirmNomba = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const navigate = useNavigate();

  const [status, setStatus] = useState("loading");

  useEffect(() => {
    const completeTransaction = async () => {
      console.log(66);
      if (!orderId) return;

      try {
        const token = JSON.parse(localStorage.getItem("abc-user"))?.token || "";
        const response = await fetch(
          `https://api.abccargoxpress.com/api/v2/Transactions/CompleteNombaTransaction?reference=${orderId}&api-version=2`,

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          console.log("Transaction confirmed successfully!");
          setStatus("success");
        } else {
          setStatus("error");
        }
      } catch (error) {
        console.error("Error confirming transaction:", error);
        setStatus("error");
      }
    };

    completeTransaction();
  }, [orderId]);

  return (
    <div className={styles.container}>
      {status === "loading" && (
        <p className={styles.message}>Confirming your transaction...</p>
      )}

      {status === "success" && (
        <div className={styles.message}>
          <h2>✅ Your wallet has been funded successfully!</h2>
          <button onClick={() => navigate("/dashboard")}>
            Go to Dashboard
          </button>
        </div>
      )}

      {status === "error" && (
        <div className={styles.message}>
          <h2>❌ Something went wrong with the transaction.</h2>
          <button onClick={() => navigate("/dashboard")}>
            Go to Dashboard
          </button>
        </div>
      )}
    </div>
  );
};

export default ConfirmNomba;
