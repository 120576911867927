import React from "react";

import { Table } from "../";

import { formatCurrency, formatDate } from "../../utils/helpers";

const cols = [
  {
    Header: "Waybill no",
    accessor: "orderId",
  },
  {
    Header: "Customer name",
    accessor: "customerName",
  },
  {
    Header: "Receiver name",
    accessor: "recieverName",
  },
  {
    Header: "Origin Hub",
    accessor: "departureHub",
  },
  {
    Header: "Destination Hub",
    accessor: "destinationHub",
  },

  {
    Header: "Number of Items",
    accessor: "numberOfItems",
  },
  {
    Header: "Total Weight",
    accessor: "totalWeight",
  },
  {
    Header: "Freight Price",
    accessor: "freightPrice",
    Cell: ({ cell }) => formatCurrency(cell.value),
  },
  {
    Header: "Insurance Price",
    accessor: "insurancePrice",
    Cell: ({ cell }) => formatCurrency(cell.value),
  },
  {
    Header: "VAT Price",
    accessor: "vatPrice",
    Cell: ({ cell }) => formatCurrency(cell.value),
  },
  {
    Header: "Packaging Cost",
    accessor: "packagingCost",
    Cell: ({ cell }) => formatCurrency(cell.value),
  },
  {
    Header: "Total Charges",
    accessor: "totalCharges",
    Cell: ({ cell }) => formatCurrency(cell.value),
  },
  {
    Header: "Net Amount",
    accessor: "netAmount",
    Cell: ({ cell }) => formatCurrency(cell.value),
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Booking Date",
    accessor: "bookingDate",
    Cell: ({ cell }) => formatDate(cell.value),
  },
  {
    Header: "Date Delivered",
    accessor: "dateDelivered",
  },
  {
    Header: "Date Arrived",
    accessor: "dateArrived",
  },
  {
    Header: "Date Assigned",
    accessor: "dateAssigned",
  },
];

const CustomerNetTable = ({ data }) => {
  return (
    <div>
      <Table
        data={data}
        paginate
        bordered
        columns={cols}
        fieldForTotal="amt"
        // rowClickHandler={goTo}
      />
    </div>
  );
};

export { CustomerNetTable };
