import React, { useRef, forwardRef } from "react";
import { useTable } from "react-table";
import classNames from "classnames";

import { useReactToPrint } from "react-to-print";
import styles from "./Table.module.scss";

const POT = ({ data, headerText, columns = [] }, ref) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div ref={ref} className={classNames(styles.tableToPrint, styles.wrapper)}>
      <div className={styles.title}>{headerText}</div>

      <table className={styles.useBorder} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const Table = forwardRef(POT);

const PrintTable = ({ data, columns, headerText }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div style={{ display: "none" }}>
        <Table
          headerText={headerText}
          data={data}
          columns={columns}
          ks
          ref={componentRef}
        />
      </div>
      <button className={styles.go} onClick={handlePrint}>
        Print
      </button>
    </>
  );
};

export { PrintTable };
