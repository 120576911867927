import { userConstants } from "../constants";

const initialState = {
  customerId: "",
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  balance: "",
  defaultPickupLocation: "",
  businessName: "",
  getUserStatus: "idle",
  verifyUserStatus: "idle",
  topWalletStatus: "idle",
  updateStatus: "idle",
  getUserErr: null,
  topWalletErr: null,
  verifyUserErr: null,
  updateErr: null,
  paystackErr: null,
  paystackStatus: "idle",
};

const returnUserProps = (oldUser, newUser = {}) => ({
  ...oldUser,
  ...newUser,
});

export function user(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case userConstants.FETCH_USER_REQUEST:
      return {
        ...state,
        getUserErr: null,
        getUserStatus: "pending",
      };

    case userConstants.FETCH_USER_FAILURE:
      return {
        ...state,
        getUserErr: payload,
        getUserStatus: "rejected",
      };

    case userConstants.FETCH_USER_SUCCESS:
      return {
        ...returnUserProps(state, payload),
        getUserErr: null,
        getUserStatus: "resolved",
      };

    case userConstants.TOP_WALLET_USER_REQUEST:
      return {
        ...state,
        topWalletErr: null,
        topWalletStatus: "pending",
      };

    case userConstants.TOP_WALLET_USER_FAILURE:
      return {
        ...state,
        topWalletErr: payload,
        topWalletStatus: "rejected",
      };

    case userConstants.TOP_WALLET_USER_SUCCESS:
      return {
        ...returnUserProps(state, payload),
        topWalletErr: null,
        topWalletStatus: "resolved",
      };
    case userConstants.VERIFY_USER_SUCCESS:
      return {
        ...returnUserProps(state, payload),
        verifyUserErr: null,
        verifyUserStatus: "resolved",
      };

    case userConstants.VERIFY_USER_REQUEST:
      return {
        ...state,
        verifyUserErr: null,
        verifyUserStatus: "pending",
      };

    case userConstants.VERIFY_USER_FAILURE:
      return {
        ...state,
        verifyUserErr: null,
        verifyUserStatus: "rejected",
      };
    case userConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        updateErr: payload,
        updateStatus: "pending",
      };

    case userConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        updateErr: payload,
        updateStatus: "rejected",
      };

    case userConstants.UPDATE_USER_SUCCESS:
      return {
        ...returnUserProps(state, payload),
        updateErr: null,
        updateStatus: "resolved",
      };

    case userConstants.SET_STATUS:
      return {
        ...state,
        [payload.key]: payload.value,
        [payload.errKey]: payload.errValue,
      };

    default:
      return state;
  }
}
