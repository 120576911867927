import { Suspense } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { ScrollToTop, NavBar, Footer } from "@components";

import {
  Home,
  About,
  Business,
  Services,
  Contact,
  Hubs,
  Login,
  ResetPassword,
  SignUp,
  PlaceOrder,
  Quote,
  NotFound,
  Legal,
  DBHome,
  Orders,
  TrackOrder,
  Transactions,
  Profile,
  TopWallet,
  OrderDetails,
  Receipt,
  CollectionOrders,
  EcommerceRegister,
} from "@pages";

import { SideNav, Spinner2, PrivateRoute } from "./fromOld/components";

import styles from "./fromOld/pages/Dashboard/Layout.module.scss";
import BookWaybill from "./fromOld/pages/Dashboard/BookWaybill";
import CustomerNet from "./fromOld/pages/Dashboard/CustomerNet";
import { Toaster } from "react-hot-toast";
import VerifyOtp from "./fromOld/pages/VerifyOtp";
import ConfirmNomba from "./fromOld/pages/Dashboard/ConfirmNomba";

const NormalLayout = () => {
  return (
    <ScrollToTop>
      <NavBar />
      <Outlet />
      <Footer />
    </ScrollToTop>
  );
};

const DBLayout = () => {
  return (
    <div className={styles.layout}>
      <aside className={styles.menu}>
        <SideNav />
      </aside>
      <main className={styles.main}>
        <div className={styles.content}>
          <ScrollToTop>
            <PrivateRoute>
              <Outlet />
            </PrivateRoute>
          </ScrollToTop>
        </div>
      </main>

      <footer className={styles.footer}>
        Copyright &copy; 2021 ABC Cargo Express
      </footer>
    </div>
  );
};

const propertyId = process.env.REACT_APP_TAWK_PROPERTY_ID;
const widgetId = process.env.REACT_APP_TAWK_WIDGET_ID;

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<NormalLayout />}>
          <Route index element={<Home />} />

          <Route path="reset-password" element={<ResetPassword />} />

          <Route path="about" element={<About />} />

          <Route path="business" element={<Business />} />

          <Route path="services" element={<Services />} />

          <Route path="hubs" element={<Hubs />} />

          <Route path="contact" element={<Contact />} />

          <Route path="login" element={<Login />} />

          <Route path="sign-up" element={<SignUp />} />

          <Route path="ecommerce" element={<EcommerceRegister />} />

          <Route path="place-order" element={<PlaceOrder />} />

          <Route path="quote" element={<Quote />} />

          <Route path="legal" element={<Legal />} />

          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/verify-otp" element={<VerifyOtp />} />

        <Route path="/dashboard" element={<DBLayout />}>
          <Route
            index
            element={
              <Suspense fallback={<Spinner2 />}>
                <DBHome />
              </Suspense>
            }
          />

          <Route path="your-orders">
            <Route
              path=":id"
              element={
                <Suspense fallback={<Spinner2 />}>
                  <OrderDetails />
                </Suspense>
              }
            />

            <Route
              index
              element={
                <Suspense fallback={<Spinner2 />}>
                  <Orders />
                </Suspense>
              }
            />
          </Route>

          <Route
            path="receipt/:id"
            element={
              <Suspense fallback={<Spinner2 />}>
                <Receipt />
              </Suspense>
            }
          />

          <Route
            path="your-transactions"
            element={
              <Suspense fallback={<Spinner2 />}>
                <Transactions />
              </Suspense>
            }
          />
          <Route
            path="confirm-nomba"
            element={
              <Suspense fallback={<Spinner2 />}>
                <ConfirmNomba />
              </Suspense>
            }
          />
          <Route
            path="book-waybill"
            element={
              <Suspense fallback={<Spinner2 />}>
                <BookWaybill />
              </Suspense>
            }
          />
          <Route
            path="customer-net"
            element={
              <Suspense fallback={<Spinner2 />}>
                <CustomerNet />
              </Suspense>
            }
          />

          <Route
            path="your-collections"
            element={
              <Suspense fallback={<Spinner2 />}>
                <CollectionOrders />
              </Suspense>
            }
          />

          <Route
            path="your-profile"
            element={
              <Suspense fallback={<Spinner2 />}>
                <Profile />
              </Suspense>
            }
          />

          <Route
            path="track-order"
            element={
              <Suspense fallback={<Spinner2 />}>
                <TrackOrder />
              </Suspense>
            }
          />

          <Route
            path="top-wallet"
            element={
              <Suspense fallback={<Spinner2 />}>
                <TopWallet />
              </Suspense>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <Toaster position="top-right" />

      <TawkMessengerReact propertyId={propertyId} widgetId={widgetId} />
    </>
  );
}

export default App;
