// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormContainer_form__3ZroY {\n  margin: 20px auto;\n  padding: 30px 10px;\n}\n.FormContainer_form__3ZroY .FormContainer_msg__YJLQ5 {\n  text-align: center;\n  white-space: pre-line;\n  margin-bottom: 30px;\n}", "",{"version":3,"sources":["webpack://src/fromOld/components/FormContainer/FormContainer.module.scss"],"names":[],"mappings":"AAEA;EAEI,iBAAA;EACA,kBAAA;AAFJ;AAII;EAEI,kBAAA;EACA,qBAAA;EACA,mBAAA;AAHR","sourcesContent":["@import \"../../../assets/scss/index.scss\";\n\n.form\n{\n    margin: 20px auto;\n    padding:30px 10px;\n\n    .msg\n    {\n        text-align: center;\n        white-space: pre-line;\n        margin-bottom: 30px;\n    }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "FormContainer_form__3ZroY",
	"msg": "FormContainer_msg__YJLQ5"
};
export default ___CSS_LOADER_EXPORT___;
