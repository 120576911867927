export const userConstants = {
  TOP_WALLET_USER_REQUEST: "TOP_WALLET_USER_REQUEST",
  TOP_WALLET_USER_SUCCESS: "TOP_WALLET_USER_SUCCESS",
  TOP_WALLET_USER_FAILURE: "TOP_WALLET_USER_FAILURE",

  VERIFY_USER_REQUEST: "VERIFY_USER_REQUEST",
  VERIFY_USER_SUCCESS: "VERIFY_USER_SUCCESS",
  VERIFY_USER_FAILURE: "VERIFY_USER_FAILURE",

  CONFIRM_PAYSTACK_REQUEST: "CONFIRM_PAYSTACK_REQUEST",
  CONFIRM_PAYSTACK_SUCCESS: "CONFIRM_PAYSTACK_SUCCESS",
  CONFIRM_PAYSTACK_FAILURE: "CONFIRM_PAYSTACK_FAILURE",

  FETCH_USER_REQUEST: "FETCH_USER_REQUEST",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE: "FETCH_USER_FAILURE",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  SET_STATUS: "SET_STATUS",
};
